import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  addDays,
  addMonths,
  format as dateFnsFormat,
  endOfDay,
} from 'date-fns';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  createProduct,
  createProducts,
  deleteProduct,
  fetchCategories,
  getAttributes,
  getSingleProduct,
  updateProduct,
} from '@app/adapter/catalog-service';
import { useNavigateAdminDelegatable as useNavigate } from '@app/administrator/utils/useNavigateAdminDelegatable';
import { ProductDateSetting } from '@app/components/Catalog/ProductDateSetting';
import { ProductRepeatWeek } from '@app/components/Catalog/ProductRepeatWeek';
import { ProductLayout } from '@app/components/Layout/ProductLayout';
import {
  AttributeSelect,
  AttributeSelectItem,
} from '@app/components/Shared/AttributeSelect';
import { CitySelect } from '@app/components/Shared/CitySelect';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import { DateField } from '@app/components/Shared/DateField';
import { NumberField } from '@app/components/Shared/NumberField';
import { PrefectureSelect } from '@app/components/Shared/PrefectureSelect';
import { TimeSelect } from '@app/components/Shared/TimeSelect';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import {
  defaultProductForm,
  ProductForm,
  productFormSchema,
} from '@app/schemas/catalog';
import { theme } from '@app/theme';
import {
  Category,
  CategoryParentName,
  Product,
  ProductInput,
  ProductType,
  SupportedProductPublicationStatus,
} from '@app/types/catalog';
import { convertNewLine } from '@app/utils';
import {
  getCalculatedDairyPay,
  getCalculatedHourlyPay,
} from '@app/utils/catalog';
import { getMenuItems } from '@app/utils/components';
import {
  defaultProductInput,
  defaultProductValue,
  notRequireClinicalDepartmentName,
  productCategoryList,
  productCommutingList,
  productEndPeriodStatusList,
  productSelectionList,
  productStatusList,
  productVariantsSkuList,
} from '@app/utils/constants';
import { restoreSanitizedString } from '@app/utils/format';
import { ProductDetail } from '@app/views/ProductDetail';

function FormControlItem(props: {
  children: ReactNode;
  message?: ReactNode;
  required?: boolean;
  title: string;
}) {
  const theme = useTheme();
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography>{props.title}</Typography>
          {props.required && (
            <Typography color={theme.customPalette.alert}>*</Typography>
          )}
        </Stack>
        {props.message}
      </Stack>
      <Box>{props.children}</Box>
    </Stack>
  );
}

function toCategoryEnum(categoryId: string, categoryList: Category[]) {
  return productCategoryList.find(
    (c) => c.name === categoryList.find((c2) => c2.id === categoryId)?.name
  )?.id as ProductForm['categoryEnum'] | undefined;
}

function toCategoryId(
  categoryEnum: ProductForm['categoryEnum'],
  categoryList: Category[]
) {
  return categoryList.find(
    (c) =>
      c.name === productCategoryList.find((c2) => c2.id === categoryEnum)?.name
  )?.id;
}

export interface ProductCreationState {
  id?: string;
  mode?: 'create' | 'copy';
}

export function ProductCreation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { productId } = useParams();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  // 診療科目リストの状態
  const [clinicalDepartmentList, setClinicalDepartmentList] = useState<
    AttributeSelectItem[] | undefined
  >(undefined);
  // 仕事種別リストの状態
  const [jobTypeList, setJobTypeList] = useState<
    AttributeSelectItem[] | undefined
  >(undefined);
  // プレビューモードかどうかの状態
  const [isPreview, setIsPreview] = useState(false);
  // データ読み込み済みかどうかの状態
  const [isLoadData, setIsLoadData] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [isLoadingDraftSave, setIsLoadingDraftSave] = useState(false);
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);
  const [productClinicalIds, setProductClinicalIds] = useState<string[]>([]);
  const notRequireDepartmentId = useMemo(
    () =>
      clinicalDepartmentList
        ?.filter(
          (clinical) => clinical.name === notRequireClinicalDepartmentName
        )
        .map((cli) => cli.id) || [],
    [clinicalDepartmentList]
  );
  const productState = useMemo(() => {
    return location.state as ProductCreationState;
  }, [location.state]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    clearErrors,
  } = useForm<ProductForm>({
    defaultValues: {
      ...defaultProductForm,
      access: convertNewLine(organizationState.customFields.access ?? ''),
      workAddress1: organizationState.addressLine3 ?? '',
      workAddress2: organizationState.customFields.addressLine4 ?? '',
      workCity: organizationState.addressLine2 ?? '',
      workPostalCode: organizationState.postalCode ?? '',
      workPrefecture: organizationState.addressLine1 ?? '',
    },
    mode: 'onChange',
    resolver: zodResolver(productFormSchema),
  });

  /** 想定時給 */
  const calculatedHourlyPay =
    watch('variantsSku') === 'hour'
      ? watch('variantsPriceAmount') ?? 0 // 時給の場合は報酬金額そのまま設定
      : getCalculatedHourlyPay(
          watch('startTime'),
          watch('endTime'),
          watch('breakTime'),
          watch('variantsPriceAmount')
        ); // 日給の場合は報酬金額÷勤務時間

  /** 想定日給 */
  const calculatedDairyPay =
    watch('variantsSku') === 'day'
      ? watch('variantsPriceAmount') ?? 0 // 日給の場合は報酬金額そのまま設定
      : getCalculatedDairyPay(
          watch('startTime'),
          watch('endTime'),
          watch('breakTime'),
          watch('variantsPriceAmount')
        ); // 時給の場合は報酬金額×勤務時間

  /** APIに渡すための求人データに変換 */
  const convertProduct = useCallback(
    (
      formValue: ProductForm,
      status: SupportedProductPublicationStatus,
      day?: string
    ) => {
      const startPeriod = new Date(formValue.startPeriod);
      const endPeriod =
        formValue.endPeriodStatus === 'prev' && day
          ? addDays(new Date(day), -1) // 勤務日前日の場合
          : formValue.endPeriodStatus === 'daysAgo' && day
          ? addDays(new Date(day), (formValue.endPeriodDayCount ?? 0) * -1) // 勤務日X日前の場合
          : formValue.endPeriod
          ? new Date(formValue.endPeriod) // 日付指定の場合
          : undefined;
      const data: ProductInput = {
        ...defaultProductInput,
        additionalInformation: formValue.additionalInformation,
        attributes: [
          ...formValue.attributesClinical.map((id) => ({
            attributeId: id,
            value: clinicalDepartmentList?.find((a) => a.id === id)?.name ?? '',
          })),
          ...formValue.attributesJob.map((id) => ({
            attributeId: id,
            value: jobTypeList?.find((a) => a.id === id)?.name ?? '',
          })),
        ],
        categoryId: toCategoryId(formValue.categoryEnum, categoryList) ?? '',
        customFields: {
          ...(formValue.categoryEnum === 'spot'
            ? {
                day: day ? dateFnsFormat(new Date(day), 'yyyy-MM-dd') : '', // NOTE:日本時間の"yyyy-mm-dd"形式で登録
                repeatDetail: '',
                repeatWeek: day ? [new Date(day).getDay()] : [],
                selection: '',
                workPublicHoliday: false,
              }
            : {
                day: '',
                repeatDetail: formValue.repeatDetail,
                repeatWeek: formValue.repeatWeek,
                selection: formValue.selection,
                workPublicHoliday: formValue.workPublicHoliday,
              }),
          access: formValue.workLocationOther
            ? formValue.access
            : organizationState.customFields.access ?? '',
          breakTime: formValue.breakTimeNoParticular
            ? 0
            : formValue.breakTime ?? 0,
          breakTimeNoParticular: formValue.breakTimeNoParticular,
          calculatedDairyPay,
          calculatedHourlyPay,
          commuting: formValue.commuting,
          commutingCost:
            formValue.commuting === 'UpperLimit' ||
            formValue.commuting === 'FixedFee' ||
            formValue.commuting === 'Include'
              ? formValue.commutingCost ?? 0
              : 0,
          count: formValue.count ?? 0,
          endTime: formValue.endTime,
          incentive: formValue.variantsDescription ?? '',
          orderConditions: formValue.orderConditions,
          organizationName: organizationState.name ?? '',
          overWorkConditions: [
            ...(formValue.possibleTimeAdjust ? ['時間調整可'] : []),
            ...(formValue.possibleOvertime ? ['残業あり'] : []),
            ...(formValue.possibleOvertime && formValue.overtimeAllowance
              ? ['残業代の追加あり']
              : []),
          ] as ProductInput['customFields']['overWorkConditions'],
          startTime: formValue.startTime,
          workAddress1: formValue.workLocationOther
            ? formValue.workAddress1
            : organizationState.addressLine3 ?? '',
          workAddress2: formValue.workLocationOther
            ? formValue.workAddress2
            : organizationState.customFields.addressLine4 ?? '',
          workPostalCode: formValue.workLocationOther
            ? formValue.workPostalCode
            : organizationState.postalCode ?? '',
        },
        description: formValue.description,
        locationIds: [
          formValue.workLocationOther
            ? formValue.workPrefecture
            : organizationState.addressLine1 ?? '',
          formValue.workLocationOther
            ? formValue.workCity
            : organizationState.addressLine2 ?? '',
        ],
        name: formValue.name,
        publication: {
          since: startPeriod.toISOString(),
          status,
          until:
            startPeriod.getTime() <= (endPeriod?.getTime() ?? 0)
              ? endPeriod
                ? endOfDay(endPeriod).toISOString()
                : ''
              : endOfDay(startPeriod).toISOString(), // NOTE：終了日の指定方法によっては開始日より前の日になる可能性があるため整合性をとるためその場合は開始日を設定
        },
        variants: [
          {
            description: formValue.variantsDescription,
            price: {
              amount: formValue.variantsPriceAmount ?? 0,
              currency: 'JPY',
              taxIncluded: true,
            },
            sku: formValue.variantsSku,
            ...(formValue.variantsId ? { id: formValue.variantsId } : {}), // NOTE:IDが既存の存在する場合はそれを更新
          },
        ],
      };
      return data;
    },
    [
      calculatedDairyPay,
      calculatedHourlyPay,
      categoryList,
      clinicalDepartmentList,
      jobTypeList,
      organizationState,
    ]
  );

  /** 新規登録する */
  const registerNewProduct = useCallback(
    async (
      formValue: ProductForm,
      status: SupportedProductPublicationStatus
    ) => {
      try {
        if (formValue.categoryEnum === 'spot') {
          const productList = formValue.days?.map((day) =>
            convertProduct(formValue, status, day)
          );
          const result = await createProducts(
            productList,
            organizationState.id
          );
          if (result.status !== 201) {
            throw new Error(`${result.status} ${result.statusText}`);
          }
          navigate(`/products/${result.data[0].id}`);
        } else {
          const data = convertProduct(formValue, status);
          const result = await createProduct(data, organizationState.id);
          if (result.status !== 201) {
            throw new Error(`${result.status} ${result.statusText}`);
          }
          navigate(`/products/${result.data.id}`);
        }
      } catch (error) {
        setSnackbarText(
          `求人の登録に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      }
    },
    [
      convertProduct,
      setSnackbarText,
      setSnackbarOpen,
      organizationState.id,
      navigate,
    ]
  );

  /** 編集内容を登録する */
  const registerEditProduct = useCallback(
    async (formValue: ProductForm) => {
      if (!productId) {
        return;
      }
      try {
        const data = convertProduct(
          formValue,
          formValue.status,
          formValue.days[0]
        );
        const result = await updateProduct(data, productId);
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        navigate(`/products/${productId}`);
      } catch (error) {
        setSnackbarText(
          `求人の更新に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      }
    },
    [convertProduct, setSnackbarText, setSnackbarOpen, productId, navigate]
  );

  /** 下書き保存ボタンクリック処理 */
  const handleClickDraftSave: Parameters<typeof handleSubmit>[0] = useCallback(
    async (formValue) => {
      setIsLoadingDraftSave(true);
      await registerNewProduct(formValue, 'DRAFT');
      setIsLoadingDraftSave(false);
    },
    [registerNewProduct]
  );

  /** 削除ボタンクリック処理 */
  const handleDeleteClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(async () => {
    setShouldShowDeleteDialog(() => true);
  }, []);

  /** プレビューボタンクリック処理 */
  const handleClickPreview: Parameters<typeof handleSubmit>[0] =
    useCallback(() => {
      setIsPreview(true);
    }, []);

  /** 登録ボタンクリック処理 */
  const handleClickRegister: Parameters<typeof handleSubmit>[0] = useCallback(
    async (formValue) => {
      setIsLoadingRegister(true);
      if (productId) {
        await registerEditProduct(formValue);
      } else {
        await registerNewProduct(formValue, 'ACTIVE');
      }
      setIsLoadingRegister(false);
    },
    [registerEditProduct, registerNewProduct, productId]
  );

  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleCloseDeleteDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm || !productId) {
        setShouldShowDeleteDialog(false);
        return;
      }
      try {
        setIsDeleting(true);
        await deleteProduct(productId);
        setSnackbarText(`${productId}の求人を削除しました。`);
        setSnackbarState('info');
        setSnackbarOpen(true);
        navigate('/products');
      } catch (error) {
        setSnackbarText(
          `求人の削除に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsDeleting(false);
        setShouldShowDeleteDialog(false);
      }
    },
    [productId, navigate, setSnackbarOpen, setSnackbarState, setSnackbarText]
  );

  /** 診療科目または仕事種別の読み込み */
  const loadAttributes = useCallback(async () => {
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['clinicalDepartment'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const clinicalList: AttributeSelectItem[] = result.data.value.map(
        (a) => ({
          group: a.items?.[0].groupName ?? '',
          id: a.id,
          name: a.items?.[0].value ?? '',
        })
      );
      setClinicalDepartmentList(clinicalList);

      const resultJob = await getAttributes({
        filter: {
          groupName: ['jobType'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (resultJob.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const jobList: AttributeSelectItem[] = resultJob.data.value.map((a) => ({
        id: a.id,
        name: a.name ?? '',
      }));
      setJobTypeList(jobList);

      return [clinicalList, jobList];
    } catch (error) {
      setSnackbarText(
        `診療科目または仕事種別の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
      return [[], []] as AttributeSelectItem[][];
    }
  }, [setSnackbarText, setSnackbarOpen]);

  /** 求人データの読み込み */
  const loadProduct = useCallback(
    async (
      categoryList: Category[],
      clinicalList: AttributeSelectItem[],
      jobList: AttributeSelectItem[]
    ) => {
      if (!productId && !productState?.id) {
        return;
      }
      const id =
        (productState?.mode === 'copy' ? productState.id : productId) || '';
      try {
        const result = await getSingleProduct(id);
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        const clinicalIds =
          result.data.attributes
            ?.filter(
              (a) =>
                clinicalList.some((i) => i.id === a.attributeId) &&
                a.value !== notRequireClinicalDepartmentName
            )
            .map((a) => a.attributeId) ?? [];
        setProductClinicalIds(clinicalIds);
        const product: Product = {
          ...defaultProductValue,
          ...result.data,
          customFields: {
            ...defaultProductValue.customFields,
            ...result.data.customFields,
          },
          ...(productState?.mode === 'copy'
            ? {
                day: undefined,
                id: productId,
                publication: {
                  publishedAt: '',
                  publishedBy: '',
                  since: dateFnsFormat(new Date(), 'yyyy/MM/dd'),
                  status: 'ACTIVE',
                  until: dateFnsFormat(
                    addDays(addMonths(new Date(), 6), -1),
                    'yyyy/MM/dd'
                  ),
                },
              }
            : undefined),
        };
        const categoryEnum =
          toCategoryEnum(product.categoryId, categoryList) ?? 'spot';
        const workPostalCode = product.customFields?.workPostalCode ?? '';
        const workCity =
          product.locations?.find((l) => l.type === 'city')?.id ?? '';
        const workPrefecture =
          product.locations?.find((l) => l.type === 'prefecture')?.id ?? '';
        const workAddress1 = product.customFields?.workAddress1 ?? '';
        const workAddress2 = product.customFields?.workAddress2 ?? '';
        const access = convertNewLine(product.customFields?.access ?? '');
        const isWorkLocationOther =
          organizationState.postalCode !== workPostalCode ||
          organizationState.addressLine1 !== workPrefecture ||
          organizationState.addressLine2 !== workCity ||
          organizationState.addressLine3 !== workAddress1 ||
          (organizationState.customFields.addressLine4 ?? '') !==
            workAddress2 ||
          convertNewLine(organizationState.customFields.access ?? '') !==
            access;
        const formValues: ProductForm = {
          access,
          additionalInformation: restoreSanitizedString(
            product.additionalInformation ?? ''
          ),
          attributesClinical:
            product.attributes
              ?.filter((a) => clinicalList.some((i) => i.id === a.attributeId))
              .map((a) => a.attributeId) ?? [],
          attributesJob:
            product.attributes
              ?.filter((a) => jobList.some((i) => i.id === a.attributeId))
              .map((a) => a.attributeId) ?? [],
          breakTime: product.customFields?.breakTime ?? null,
          breakTimeNoParticular:
            product.customFields?.breakTimeNoParticular ?? false,
          categoryEnum,
          commuting: product.customFields
            ?.commuting as ProductForm['commuting'],
          commutingCost: product.customFields?.commutingCost ?? null,
          count: product.customFields?.count,
          days: product.customFields?.day
            ? [dateFnsFormat(new Date(product.customFields?.day), 'yyyy/MM/dd')]
            : [],
          description: restoreSanitizedString(product.description),
          endPeriod: product.publication?.until
            ? dateFnsFormat(new Date(product.publication?.until), 'yyyy/MM/dd')
            : '',
          endPeriodDayCount: null,
          endPeriodStatus:
            productState?.mode === 'copy' && categoryEnum === ProductType.SPOT
              ? 'prev'
              : 'day',
          endTime: product.customFields?.endTime ?? '',
          inputMode: productState?.mode ?? 'create',
          isNotRequireClinicalDepartment: !!product.attributes.some(
            (att) => att.value === notRequireClinicalDepartmentName
          ),
          name: product.name ?? '',
          orderConditions: convertNewLine(
            product.customFields?.orderConditions ?? ''
          ),
          overtimeAllowance: product.customFields?.overWorkConditions?.some(
            (v) => v === '残業代の追加あり'
          ),
          possibleOvertime: product.customFields?.overWorkConditions?.some(
            (v) => v === '残業あり'
          ),
          possibleTimeAdjust: product.customFields?.overWorkConditions?.some(
            (v) => v === '時間調整可'
          ),
          repeatDetail: convertNewLine(
            product.customFields?.repeatDetail ?? ''
          ),
          repeatWeek:
            categoryEnum === 'regularPartTime'
              ? product.customFields?.repeatWeek ?? []
              : [], // NOTE:spotの場合も登録はされているが項目は定期非常勤用のため設定しない
          selection: product.customFields?.selection ?? '',
          startPeriod: product.publication?.since
            ? dateFnsFormat(new Date(product.publication?.since), 'yyyy/MM/dd')
            : '',
          startTime: product.customFields?.startTime ?? '',
          status:
            (product.publication.status as ProductForm['status']) ?? 'ACTIVE',
          variantsDescription: convertNewLine(
            product.variants?.[product.variants.length - 1]?.description ?? ''
          ),
          variantsId:
            productState?.mode !== 'copy'
              ? product.variants?.[product.variants.length - 1]?.id
              : undefined, // NOTE:複製する場合は登録時に新たなIDが設定されるようにここでは何も入れない
          variantsPriceAmount:
            product.variants?.[product.variants.length - 1]?.price.amount ??
            null,
          variantsSku:
            product.variants?.[product.variants.length - 1]?.sku ?? 'day',
          workAddress1,
          workAddress2,
          workCity,
          workLocationOther: isWorkLocationOther,
          workPostalCode,
          workPrefecture,
          workPublicHoliday: product.customFields?.workPublicHoliday ?? false,
        }; // NOTE:念のためレスポンスデータに存在しない項目があれば初期値を設定
        reset(formValues);
      } catch (error) {
        setSnackbarText(
          `求人の取得に失敗しました, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      }
    },
    [
      reset,
      setSnackbarText,
      setSnackbarOpen,
      productId,
      productState,
      organizationState,
    ]
  );

  /** 案件区分の読み込み */
  const loadCategories = useCallback(async () => {
    try {
      const result = await fetchCategories();
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const category = result.data.value.find(
        (c) => c.name === CategoryParentName.PRO_DOCTOR
      );
      const list = category?.children ?? [];
      setCategoryList(list);
      return list;
    } catch (error) {
      setSnackbarText(
        `案件区分の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
      return [];
    }
  }, [setSnackbarText, setSnackbarOpen]);

  useEffect(() => {
    void (async () => {
      const [clinicalList, jobList] = await loadAttributes();
      const categoryList = await loadCategories();
      await loadProduct(categoryList, clinicalList, jobList);
      setIsLoadData(true);
    })();
  }, [loadAttributes, loadProduct, loadCategories]);

  // プレビュー表示時
  if (isPreview) {
    const productInput = convertProduct(
      getValues(),
      'ACTIVE',
      watch('days')[0]
    );
    return (
      <ProductDetail
        previewMode
        productInput={productInput}
        onClose={() => setIsPreview(false)}
      />
    );
  }

  // 編集、複製の場合、求人データが読み込めるまで内容を表示しない
  if ((productId || productState?.mode === 'copy') && !isLoadData) {
    return (
      <ProductLayout
        sx={{
          minWidth: 1000,
          px: 0,
        }}
      >
        {productId ? (
          <Typography variant="h5">{productId}を編集</Typography>
        ) : (
          <Typography variant="h5">新規登録</Typography>
        )}
        <CircularProgress sx={{ m: 'auto' }} />
      </ProductLayout>
    );
  }

  return (
    <form>
      <ProductLayout sx={{ minWidth: 1000, px: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={2}
        >
          {productState?.mode === 'copy' ? (
            <Typography variant="h5">
              {productId}の複製を元に新規登録
            </Typography>
          ) : productId ? (
            <Typography variant="h5">{productId}を編集</Typography>
          ) : (
            <Typography variant="h5">新規登録</Typography>
          )}
          <Stack direction="row" spacing={2} alignItems="center">
            {productId ? (
              <LoadingButton
                variant="outlined"
                color="secondary"
                size="small"
                loading={isLoadingDraftSave}
                onClick={handleSubmit(handleClickDraftSave)}
                sx={{ width: '120px' }}
              >
                下書き保存
              </LoadingButton>
            ) : (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={handleDeleteClick}
                sx={{ width: '120px' }}
              >
                削除
              </Button>
            )}
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{
                display: 'none',
                width: '120px',
              }}
              onClick={handleSubmit(handleClickPreview)}
            >
              プレビュー
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit(handleClickRegister)}
              loading={isLoadingRegister}
              sx={{ width: '120px' }}
            >
              {productId ? '登録' : '保存'}
            </LoadingButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={3} px={2}>
          {productId && (
            <>
              <FormControlItem title="ステータス" required>
                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        {...field}
                        error={!!error}
                        displayEmpty
                        sx={{ bgcolor: theme.palette.common.white }}
                      >
                        {getMenuItems(productStatusList)}
                      </Select>
                      {error && (
                        <FormHelperText error sx={{ ml: 2 }}>
                          {error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControlItem>
              <Divider />
            </>
          )}
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography color="red">*</Typography>
            <Typography>は必須事項です</Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ width: '12rem' }}>
              <FormControlItem title="案件区分" required>
                <Controller
                  name="categoryEnum"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        {...field}
                        error={!!error}
                        fullWidth
                        onChange={(event) => {
                          const value = event.target.value;
                          field.onChange(value);
                          setValue(
                            'endPeriodStatus',
                            value === ProductType.SPOT ? 'prev' : 'day'
                          );
                        }}
                      >
                        {getMenuItems(productCategoryList)}
                      </Select>
                      {error && (
                        <FormHelperText error sx={{ ml: 2 }}>
                          {error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControlItem>
            </Box>
            <FormControlItem title="掲載期間" required>
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name="startPeriod"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateField
                      {...field}
                      inputRef={field.ref}
                      format="yyyy/MM/dd"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ width: '150px' }}
                    />
                  )}
                />
                <Typography>～</Typography>
                <Controller
                  name="endPeriodStatus"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      sx={{ width: '9rem' }}
                      disabled={
                        watch('categoryEnum') === ProductType.REGULAR_PART_TIME
                      }
                      displayEmpty
                    >
                      {getMenuItems(productEndPeriodStatusList)}
                    </Select>
                  )}
                />
                {(watch('categoryEnum') === 'regularPartTime' ||
                  watch('endPeriodStatus') === 'day') && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>終了日</Typography>
                    <Controller
                      name="endPeriod"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateField
                          {...field}
                          ref={undefined}
                          inputRef={field.ref}
                          format="yyyy/MM/dd"
                          error={!!error}
                          sx={{ width: '150px' }}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Stack>
                )}
                {watch('categoryEnum') === 'spot' &&
                  watch('endPeriodStatus') === 'daysAgo' && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography>勤務日</Typography>
                      <Controller
                        name="endPeriodDayCount"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <NumberField
                            {...field}
                            inputRef={field.ref}
                            error={!!error}
                            helperText={error?.message}
                            sx={{ width: '48px' }}
                          />
                        )}
                      />
                      <Typography>日前</Typography>
                    </Stack>
                  )}
              </Stack>
            </FormControlItem>
          </Stack>
          <FormControlItem
            title="求人案件名"
            required
            message={
              <Typography color={theme.palette.grey[500]}>
                管理者用の名称であり、応募する人には見えません。
              </Typography>
            }
          >
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  name="product-name"
                  error={!!error}
                  placeholder="平日当直案件、土曜日当直担当 等"
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </FormControlItem>
          <Divider />
          {watch('categoryEnum') === 'spot' &&
            (productId ? (
              <FormControlItem title="募集する勤務日" required>
                <Controller
                  name="days"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateField
                      {...field}
                      inputRef={field.ref}
                      format="yyyy/MM/dd"
                      value={field.value?.[0] ?? ''} // NOTE:日付の配列に設定するため直接valueとonChangeを設定
                      error={!!error}
                      helperText={error?.message}
                      onChange={(value) => {
                        field.onChange([value]);
                      }}
                      sx={{ width: '150px' }}
                    />
                  )}
                />
              </FormControlItem>
            ) : (
              <FormControlItem
                title="募集する勤務日"
                required
                message={
                  <Typography color={theme.palette.grey[500]}>
                    半年先までの求人を一括で登録可能です。
                  </Typography>
                }
              >
                <Controller
                  name="days"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <ProductDateSetting
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      startDate={watch('startPeriod') || undefined}
                    />
                  )}
                />
              </FormControlItem>
            ))}
          {watch('categoryEnum') === 'regularPartTime' && (
            <>
              <FormControlItem title="募集曜日" required>
                <Controller
                  name="repeatWeek"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <ProductRepeatWeek
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControlItem>
              <FormControlItem title="募集曜日の詳細" required>
                <Controller
                  name="repeatDetail"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      inputRef={field.ref}
                      error={!!error}
                      minRows={2}
                      placeholder="毎週月曜日、第2土曜日、隔週水曜日・木曜日　等"
                      helperText={error?.message}
                      multiline
                      sx={{ width: '35rem' }}
                    />
                  )}
                />
              </FormControlItem>
              <Controller
                name="workPublicHoliday"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControlLabel
                    {...field}
                    checked={field.value}
                    control={<Checkbox />}
                    label="対象曜日が祝日の場合も勤務あり"
                    sx={{ width: 'fit-content' }}
                  />
                )}
              />
            </>
          )}
          <Divider />
          <Grid container>
            <Grid
              item
              xs={6}
              display="flex"
              flexDirection="column"
              gap={2}
              pr={2}
            >
              <FormControlItem title="募集科目" required>
                <Controller
                  name="isNotRequireClinicalDepartment"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      label={notRequireClinicalDepartmentName}
                      sx={{ width: 'fit-content' }}
                      control={<Checkbox />}
                      onChange={(e, checked) => {
                        if (checked) {
                          clearErrors('attributesClinical');
                        }
                        if (productId || productState?.id) {
                          setValue(
                            'attributesClinical',
                            checked
                              ? notRequireDepartmentId
                              : productClinicalIds
                          );
                        } else {
                          setValue(
                            'attributesClinical',
                            checked ? notRequireDepartmentId : []
                          );
                        }
                        field.onChange(e, checked);
                      }}
                    />
                  )}
                />
                {!watch('isNotRequireClinicalDepartment') && (
                  <Controller
                    name="attributesClinical"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <AttributeSelect
                        {...field}
                        label="科目を選択"
                        list={clinicalDepartmentList}
                        error={!!error}
                        helperText={error?.message}
                        isGroup
                      />
                    )}
                  />
                )}
              </FormControlItem>

              <FormControlItem title="仕事種別" required>
                <Controller
                  name="attributesJob"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <AttributeSelect
                      {...field}
                      label="仕事種別を選択"
                      list={jobTypeList}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControlItem>
              <FormControlItem title="仕事内容" required>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      inputRef={field.ref}
                      error={!!error}
                      minRows={2}
                      placeholder="仕事内容を入力"
                      helperText={error?.message}
                      multiline
                      fullWidth
                    />
                  )}
                />
              </FormControlItem>
              <FormControlItem title="応募条件">
                <Controller
                  name="orderConditions"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      inputRef={field.ref}
                      error={!!error}
                      minRows={2}
                      placeholder="応募条件を入力"
                      helperText={error?.message}
                      multiline
                      fullWidth
                    />
                  )}
                />
              </FormControlItem>
              {watch('categoryEnum') === 'regularPartTime' && (
                <FormControlItem title="選考方法">
                  <Controller
                    name="selection"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Select
                          {...field}
                          error={!!error}
                          displayEmpty
                          renderValue={(selected: string) => {
                            return selected ? (
                              selected
                            ) : (
                              <Typography color={theme.palette.grey[500]}>
                                選考方法を選択
                              </Typography>
                            );
                          }}
                          sx={{ width: '12rem' }}
                        >
                          {getMenuItems(productSelectionList)}
                        </Select>
                        {error && (
                          <FormHelperText error sx={{ ml: 2 }}>
                            {error.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </FormControlItem>
              )}
              <FormControlItem title="その他情報">
                <Controller
                  name="additionalInformation"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      inputRef={field.ref}
                      error={!!error}
                      minRows={2}
                      placeholder="その他情報を入力"
                      helperText={error?.message}
                      multiline
                      fullWidth
                    />
                  )}
                />
              </FormControlItem>
            </Grid>
            <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
              <FormControlItem title="勤務先" required>
                <Select
                  value={organizationState.name}
                  disabled={watch('workLocationOther')}
                  displayEmpty
                  fullWidth
                  sx={{
                    bgcolor: !watch('workLocationOther')
                      ? theme.palette.common.white
                      : theme.palette.action.disabledBackground,
                  }}
                >
                  {getMenuItems([organizationState.name])}
                </Select>
                <Controller
                  name="workLocationOther"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      label="他の勤務地情報を入力する"
                      control={<Checkbox />}
                      onChange={(e, checked) => {
                        if (!checked) {
                          // 勤務先で選ばれている勤務地情報を設定
                          setValue(
                            'workPostalCode',
                            organizationState.postalCode
                          );
                          setValue(
                            'workPrefecture',
                            organizationState.addressLine1
                          );
                          setValue('workCity', organizationState.addressLine2);
                          setValue(
                            'workAddress1',
                            organizationState.addressLine3
                          );
                          setValue(
                            'workAddress2',
                            organizationState.customFields.addressLine4 ?? ''
                          );
                          setValue(
                            'access',
                            organizationState.customFields.access ?? ''
                          );
                        } else {
                          setValue('workPostalCode', '');
                          setValue('workPrefecture', '');
                          setValue('workCity', '');
                          setValue('workAddress1', '');
                          setValue('workAddress2', '');
                          setValue('access', '');
                        }
                        field.onChange(e, checked);
                      }}
                    />
                  )}
                />
              </FormControlItem>
              <FormControlItem title="勤務地情報" required>
                <Stack spacing={1}>
                  <Controller
                    name="workPostalCode"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={!watch('workLocationOther')}
                        inputRef={field.ref}
                        InputProps={{
                          sx: { bgcolor: theme.palette.common.white },
                        }}
                        placeholder="郵便番号を入力"
                        error={!!error}
                        helperText={error?.message}
                        sx={{ width: '12rem' }}
                      />
                    )}
                  />
                  <Stack direction="row" spacing={2}>
                    <Controller
                      name="workPrefecture"
                      control={control}
                      rules={{ required: '都道府県を選択してください' }}
                      render={({ field, fieldState: { error } }) => (
                        <PrefectureSelect
                          {...field}
                          inputRef={field.ref}
                          disabled={!watch('workLocationOther')}
                          error={!!error}
                          helperText={error?.message}
                          fullWidth
                          onChange={(e) => {
                            setValue('workCity', '');
                            field.onChange(e);
                          }}
                          sx={{
                            '.Mui-disabled': {
                              WebkitTextFillColor: watch('workPrefecture')
                                ? `${theme.palette.text.primary} !important`
                                : undefined,
                            },
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="workCity"
                      control={control}
                      rules={{ required: '市町村を選択してください' }}
                      render={({ field, fieldState: { error } }) => (
                        <CitySelect
                          {...field}
                          inputRef={field.ref}
                          disabled={!watch('workLocationOther')}
                          prefecture={watch('workPrefecture')}
                          error={!!error}
                          helperText={error?.message}
                          fullWidth
                          sx={{
                            '.Mui-disabled': {
                              WebkitTextFillColor: watch('workCity')
                                ? `${theme.palette.text.primary} !important`
                                : undefined,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="workAddress1"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={!watch('workLocationOther')}
                        inputRef={field.ref}
                        error={!!error}
                        helperText={error?.message}
                        placeholder="町名・番地を入力"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="workAddress2"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={!watch('workLocationOther')}
                        ref={undefined}
                        inputRef={field.ref}
                        error={!!error}
                        helperText={error?.message}
                        placeholder="建物名・部屋番号を入力"
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </FormControlItem>
              <FormControlItem title="アクセス情報" required>
                <Controller
                  name="access"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={!watch('workLocationOther')}
                      inputRef={field.ref}
                      error={!!error}
                      minRows={2}
                      placeholder={`アクセス情報を入力\r例）ＪＲ山手線新宿駅から徒歩５分`}
                      helperText={error?.message}
                      multiline
                      fullWidth
                      sx={{
                        '.Mui-disabled': {
                          WebkitTextFillColor: watch('access')
                            ? `${theme.palette.text.primary} !important`
                            : `${theme.palette.grey[800]} !important`,
                        },
                      }}
                    />
                  )}
                />
              </FormControlItem>
              <FormControlItem title="給与" required>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Controller
                    name="variantsSku"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} sx={{ width: '6rem' }}>
                        {getMenuItems(productVariantsSkuList)}
                      </Select>
                    )}
                  />
                  <Controller
                    name="variantsPriceAmount"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <NumberField
                        {...field}
                        inputRef={field.ref}
                        error={!!error}
                        placeholder="金額を入力"
                        helperText={error?.message}
                        sx={{ width: '10rem' }}
                      />
                    )}
                  />
                  <Typography>円</Typography>
                </Stack>
                {watch('variantsSku') === 'day' && !!calculatedHourlyPay && (
                  <Stack>
                    <Typography>
                      想定時給：{calculatedHourlyPay.toLocaleString()}
                      （日給 ÷（勤務時間 - 休憩時間））
                    </Typography>
                    <Typography>※求人広告に表示されます</Typography>
                  </Stack>
                )}
                {watch('variantsSku') === 'hour' && !!calculatedDairyPay && (
                  <Stack>
                    <Typography>
                      想定日給：{calculatedDairyPay.toLocaleString()}
                      （時給 ×（勤務時間 - 休憩時間））
                    </Typography>
                    <Typography>※求人広告に表示されます</Typography>
                  </Stack>
                )}
              </FormControlItem>
              <FormControlItem title="勤務時間" required>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Controller
                    name="startTime"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TimeSelect
                        {...field}
                        startTime="00:00"
                        endTime="23:55"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Typography>～</Typography>
                  <Controller
                    name="endTime"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TimeSelect
                        {...field}
                        startTime="00:00"
                        endTime="96:55"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Stack>
                <Stack my={1}>
                  <Controller
                    name="possibleTimeAdjust"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        {...field}
                        checked={field.value}
                        label="時間調整可"
                        control={<Checkbox />}
                        sx={{ width: 'fit-content' }}
                      />
                    )}
                  />
                  <Controller
                    name="possibleOvertime"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        {...field}
                        checked={field.value}
                        label="残業あり"
                        control={<Checkbox />}
                        sx={{ width: 'fit-content' }}
                      />
                    )}
                  />
                  <Controller
                    name="overtimeAllowance"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        {...field}
                        checked={field.value}
                        disabled={!watch('possibleOvertime')}
                        label="残業代の追加あり"
                        control={<Checkbox />}
                        sx={{ width: 'fit-content' }}
                      />
                    )}
                  />
                </Stack>
              </FormControlItem>
              <FormControlItem title="休憩時間" required>
                <Controller
                  name="breakTimeNoParticular"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      label="随時・なし"
                      control={<Checkbox />}
                      onChange={(e, checked) => {
                        if (checked) {
                          setValue('breakTime', 0);
                        }
                        field.onChange(e, checked);
                      }}
                      sx={{ width: 'fit-content' }}
                    />
                  )}
                />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Controller
                    name="breakTime"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <NumberField
                        {...field}
                        inputRef={field.ref}
                        disabled={watch('breakTimeNoParticular') ?? false}
                        error={!!error}
                        placeholder="休憩時間を入力"
                        helperText={error?.message}
                        sx={{ width: '6rem' }}
                      />
                    )}
                  />
                  <Typography>分</Typography>
                </Stack>
              </FormControlItem>
              <FormControlItem title="インセンティブ（ある場合は具体的な条件をご記載ください）">
                <Controller
                  name="variantsDescription"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      inputRef={field.ref}
                      error={!!error}
                      minRows={2}
                      placeholder="入院1件5000円、救急車1件5000円"
                      helperText={error?.message}
                      multiline
                      fullWidth
                    />
                  )}
                />
              </FormControlItem>
              <FormControlItem title="交通費" required>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Controller
                    name="commuting"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} sx={{ width: '14rem' }}>
                        {getMenuItems(productCommutingList)}
                      </Select>
                    )}
                  />
                  {(watch('commuting') === 'UpperLimit' ||
                    watch('commuting') === 'FixedFee' ||
                    watch('commuting') === 'Include') && (
                    <>
                      <Controller
                        name="commutingCost"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <NumberField
                            {...field}
                            inputRef={field.ref}
                            error={!!error}
                            placeholder="金額を入力"
                            helperText={error?.message}
                            sx={{ width: '8rem' }}
                          />
                        )}
                      />
                      <Typography>円</Typography>
                    </>
                  )}
                </Stack>
              </FormControlItem>
              <FormControlItem title="募集人数" required>
                <Controller
                  name="count"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <NumberField
                      {...field}
                      inputRef={field.ref}
                      placeholder="募集人数を入力"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ width: '6rem' }}
                    />
                  )}
                />
              </FormControlItem>
            </Grid>
          </Grid>
        </Stack>
      </ProductLayout>
      <ConfirmDialog
        title="本当に削除しますか？"
        okButtonText="削除"
        okButtonLoading={isDeleting}
        open={shouldShowDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </form>
  );
}
